import { render, staticRenderFns } from "./login.vue?vue&type=template&id=5b79a6bf&"
import script from "./login.vue?vue&type=script&lang=js&"
export * from "./login.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CIcon: require('@chakra-ui/vue').CIcon, CInputRightElement: require('@chakra-ui/vue').CInputRightElement, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormControl: require('@chakra-ui/vue').CFormControl, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CLink: require('@chakra-ui/vue').CLink, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CAlertIcon: require('@chakra-ui/vue').CAlertIcon, CAlertDescription: require('@chakra-ui/vue').CAlertDescription, CAlert: require('@chakra-ui/vue').CAlert})
