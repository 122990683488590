<template>
  <auth-container>
    <c-heading
      as="h1"
      color="brand.900"
      fontSize="36px"
      fontWeight="600"
      marginBottom="80px"
    >
      Selamat Datang
    </c-heading>
    <c-box w="100%" textAlign="left">
      <form @submit.prevent="login">
        <c-form-control marginBottom="30px">
          <c-form-label
            for="email"
            fontSize="14px"
            color="#555555"
            fontWeigh="400"
            fontFamily="Roboto"
          >
            Email address
          </c-form-label>
          <c-input-group size="md">
            <c-input
              type="email"
              is-invalid
              error-border-color="red.300"
              id="email"
              aria-describedby="email-helper-text"
              height="62px"
              v-model="email"
            />
            <c-input-right-element v-if="false" width="4.5rem" mt="10px">
              <c-icon name="check-circle" size="18px" color="green.500" />
              <c-icon name="exclamation" size="18px" color="red.500" />
            </c-input-right-element>
          </c-input-group>
        </c-form-control>
        <c-form-control marginBottom="30px">
          <c-form-label
            for="email"
            fontSize="14px"
            color="#555555"
            fontWeigh="400"
            fontFamily="Roboto"
          >
            Kata Sandi
          </c-form-label>
          <c-input-group size="md">
            <c-input
              id="password"
              value="12345"
              height="62px"
              :type="[showPassword ? 'text' : 'password']"
              v-model="password"
            />
            <c-input-right-element width="4.5rem">
              <c-button
                h="1.75rem"
                mt="22px"
                px="0"
                py="0"
                backgroundColor="transparent"
                @click="showPassword = !showPassword"
                :class="[showPassword] ? 'show-pass' : 'hide-pass'"
                :_focus="{
                  backgroundColor: 'transparent',
                }"
                :_hover="{
                  backgroundColor: 'transparent',
                }"
              >
                <c-image src="/images/icon-eye.png" alt="icon" />
              </c-button>
            </c-input-right-element>
          </c-input-group>
        </c-form-control>
        <c-box display="flex" justifyContent="end" marginBottom="50px">
          <c-link
            as="router-link"
            to="/forgot-password"
            color="brand.900"
            fontSize="14px"
          >
            Lupa Kata Sandi?
          </c-link>
        </c-box>
        <c-form-control marginBottom="30px">
          <c-button
            variant-color="brand"
            variant="solid"
            px="26px"
            fontSize="18px"
            fontWeight="500"
            py="26px"
            borderRadius="58px"
            width="100%"
            type="submit"
          >
            Login
          </c-button>
        </c-form-control>
      </form>

      <c-box textAlign="center" marginBottom="30px" pos="relative">
        <c-box
          display="block"
          width="100%"
          height="1px"
          bgColor="#C4C4C4"
          pos="absolute"
          top="50%"
          left="0"
          marginTop="-0.5px"
        />
        <c-box
          as="span"
          pos="relative"
          zIndex="1"
          color="#C4C4C4"
          display="inline-block"
          bgColor="white"
          py="6px"
          px="12px"
          fontSize="14px"
        >
          atau
        </c-box>
      </c-box>
      <c-button
        border="1px solid #C4C4C4"
        borderRadius="100px"
        bg="white"
        w="100%"
        color="#888888"
        py="25px"
        fontSize="18px"
        fontWeight="500"
        fontFamily="Roboto"
        @click.prevent.stop="googleLogin()"
      >
        <c-image src="/images/icon-google.png" alt="google" mr="10px" />
        Masuk dengan Google
      </c-button>
      <c-text
        mt="30px"
        color="#888888"
        fontSize="16px"
        textAlign="center"
        fontFamily="Roboto"
      >
        Belum punya akun?
        <c-link
          color="brand.900"
          fontWeight="500"
          textDecoration="underline"
          as="router-link"
          :to="{ name: 'auth.register' }"
        >
          Register
        </c-link>
      </c-text>
    </c-box>

    <Portal to="main" v-if="errors.length > 0">
      <c-box pos="fixed" bottom="20px" right="20px" z-index="1001">
        <c-alert status="error">
          <c-alert-icon />
          <c-alert-description>
            <template v-for="error in errors">
              <c-text :key="error">{{ error }}</c-text>
            </template>
          </c-alert-description>
        </c-alert>
      </c-box>
    </Portal>
    <Portal to="main" v-if="isLoggingIn">
      <c-box pos="fixed" bottom="20px" right="20px" z-index="1001">
        <c-alert status="info">
          <c-alert-description> Memuat data...</c-alert-description>
        </c-alert>
      </c-box>
      Ï
    </Portal>
  </auth-container>
</template>

<script>
import equal from "fast-deep-equal"
import Logo from "@/assets/logo-white.svg"
import AuthContainer from "@/components/auth-container.vue"
import { mapActions, mapState } from "vuex"

export default {
  name: "loginPage",
  components: {
    AuthContainer,
  },
  data() {
    return {
      Logo,
      showPassword: false,
      email: "",
      password: "",
      // ---
      errors: [],
      couldNotOpenPopup: false,
      isLoggingIn: false,
    }
  },
  computed: {
    ...mapState({
      token: (s) => s.auth.token,
      user: (s) => s.auth.user,
      profile: (s) => s.profile.dataProfile,
    }),
    authData() {
      return [this.token, this.user]
    },
  },
  watch: {
    authData: {
      immediate: true,
      async handler([token, user]) {
        if (token == null || user == null) return

        if (this.$route.query.next != null) {
          await this.$router.replace(this.$route.query.next)
        } else if (user.role === "nutritionist") {
          let profile = await this.$store.dispatch("profile/getProfile")
          let whitelistedKeys = [
            "firstName",
            "email",
            "str",
            "title",
            "education",
            "specialization",
          ]
          let data = Object.entries(profile).filter(([key]) =>
            whitelistedKeys.includes(key)
          )
          let someNull = data.some((it) => it[1] == null)

          if (someNull) {
            return this.$router.replace({ name: "client.profile" })
          } else {
            return this.$router.replace({
              name: "nutrisionist.managementClient",
            })
          }
        } else if (user.role === "super_admin") {
          return this.$router.replace("/management")
        } else if (
          user.role === "client" &&
          user.clientType === "paying_client"
        ) {
          let program = await this.$store.dispatch(
            "mealPlan/getLatestProgramForClient"
          )
          console.log("program", program)
          if (program.nutritionist == null) {
            return this.$router.push({
              name: "client.success",
              query: { order_id: "xxxx" },
            })
          }
        }
        this.$router.replace("/")
      },
    },
    errors(val, old) {
      if (equal(val, old)) return
      if (val?.length <= 0) return

      this.$nextTick(() => {
        let id = setTimeout(() => {
          if (id) clearTimeout(id)

          this.errors = []
        }, 3000)
      })
    },
  },
  methods: {
    ...mapActions({
      signin: "auth/signin",
    }),
    login() {
      this.errors = []
      this.signin({ email: this.email, password: this.password })
        .then((r) => {
          console.log("r", r)
        })
        .catch((err) => {
          this.errors = [...err]
        })
    },
    googleLogin() {
      this.isLoggingIn = true
      this.win = window.open("about:blank", "_blank")
      this.axios
        .post(`/v1/auth/generate-google-url`)
        .then((r) => r.data.data)
        .then((url) => {
          this.win.location.href = url
        })
    },
    onWindowMessage($event) {
      let isSameOrigin =
        window.location.origin === $event.target.location.origin &&
        window === $event.target
      let isContainsGoogleCode = $event.data.code != null

      if (isSameOrigin && isContainsGoogleCode) {
        this.win.close()
        this.axios
          .post(`/v1/auth/login-oauth`, $event.data)
          .then((r) => r.data.data)
          .then((r) => {
            let { token, refreshToken, ...user } = r
            this.$store.commit("auth/setUser", user)
            this.$store.commit("auth/setToken", token)
            this.$store.commit("auth/setRefreshToken", refreshToken)

            this.$router.replace("/")
          })
          .catch((err) => {
            console.error("error while trying to signin with google code", err)

            let msg = err.response.data?.message
            if (msg.includes("user doesn't exists")) {
              this.$router.replace({ name: "auth.register" })
            } else {
              this.$errorToastFromCatch(err)
              // this.errors = [msg]
            }
          })
          .finally(() => {
            this.isLoggingIn = false
          })
      }
    },
  },
  mounted() {
    window.addEventListener("message", this.onWindowMessage)
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onWindowMessage)
  },
}
</script>

<style></style>
